<template>
  <Dialog :name="name" width="800px" class="change-column">
    <template #title>
      <div>Изменение колонок таблицы</div>
    </template>
    <el-transfer
      v-model="value"
      :titles="['Доступно к отображению', 'Отображается']"
      :data="data"
    />

    <div class="change-column__btns">
      <iq-button size="small" color="gray" @onClick="handleClose">
        Отменить
      </iq-button>

      <iq-button size="small" @onClick="handleConfirm"> Подтвердить </iq-button>
    </div>
  </Dialog>
</template>

<script>
import {
  GET_ACTIVE_TABLE_COLUMN,
  SET_ACTIVE_TABLE_COLUMN_ACTION,
} from '@/views/manager/store/actions'
import { GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { MANAGER_CHANGE_TABLE_COLUMN } from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import {
  timeslotsTableFieldsDesktop,
  timeslotsTableFieldsDesktopStep,
} from '@/views/manager/data/manager'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
export default {
  name: 'DialogChangeTableColumn',
  components: { IqButton, Dialog },
  data() {
    return {
      name: MANAGER_CHANGE_TABLE_COLUMN,
      value: [],
    }
  },
  computed: {
    ...mapGetters({
      getActiveColumn: GET_ACTIVE_TABLE_COLUMN,
      getCurrentSlug: GET_TERMINAL_CURRENT_ID_SLUG,
    }),
    data() {
      return this.getCurrentSlug === 'step'
        ? timeslotsTableFieldsDesktopStep
        : timeslotsTableFieldsDesktop
    },
  },
  watch: {
    getActiveColumn: {
      immediate: true,
      handler(val) {
        this.value = val
      },
    },
  },
  methods: {
    handleClose() {
      this.setDialog({ name: this.name })
    },
    handleConfirm() {
      this.$store.dispatch(SET_ACTIVE_TABLE_COLUMN_ACTION, this.value)
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.change-column
  .el-transfer
    display: flex
    justify-content: space-between
    align-items: center

  .el-transfer-panel
    width: 290px

  &__btns
    margin-top: 20px
    display: flex
    justify-content: space-around
    align-items: center
</style>
